var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.12.0-auth"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import {
  SENTRY_DSN,
  SENTRY_ENABLE,
  SENTRY_CAPTURE_RATE,
  SENTRY_ALLOWED_URLS,
  SENTRY_TUNNEL_URL,
} from '@repo/sentry-config/sentry.constants';

Sentry.init({
  // Run Sentry if enabled
  enabled: SENTRY_ENABLE,
  // Provide Sentry's Secret Key
  dsn: SENTRY_DSN,
  // Application release name or version
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE || 'unnamed',
  // Sentry's Error Transport Mechanism
  transport: Sentry.makeFetchTransport,
  // Sentry's Stack Trace Parser
  stackParser: Sentry.defaultStackParser,
  // All supported Integrations by us
  integrations: [
    Sentry.dedupeIntegration(),
    Sentry.httpContextIntegration(),
    Sentry.breadcrumbsIntegration(),
    Sentry.linkedErrorsIntegration(),
  ],
  // We only want to allow ingestion from these pre-selected allowed URLs
  allowUrls: SENTRY_ALLOWED_URLS,
  // Percentage of events to send to Sentry (for performance metrics)
  tracesSampleRate: SENTRY_CAPTURE_RATE,
  // Percentage of events to send to Sentry (for session replays)
  replaysSessionSampleRate: 0,
  // Percentage of events to send to Sentry (for session replays when error happens)
  replaysOnErrorSampleRate: 1.0,
  // Provides a custom Sentry Tunnel Router
  // @note these are components of the Sentry DSN string
  // @see @sentry/nextjs/esm/client/tunnelRoute.js
  tunnel: SENTRY_TUNNEL_URL(`?o=4506191161786368&p=4506191307735040`),
  // Adds custom filtering before sending an Event to Sentry
  beforeSend: (event, hint) => {
    // Attempts to grab the original Exception before any "magic" happens
    const exception = hint.originalException as Error;

    // We only want to capture Errors that have a Stack Trace and that are not Anonymous Errors
    return exception.stack && !exception.stack.includes('<anonymous>')
      ? event
      : null;
  },
});

// Loads this Dynamically to avoid adding this to the main bundle (initial load)
void import('@sentry/nextjs').then(({ replayIntegration, BrowserTracing }) => {
  Sentry.addIntegration(
    replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  );
  Sentry.addIntegration(new BrowserTracing());
});
